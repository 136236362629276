*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html , body , #root{
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;