.CircularProgressbar .CircularProgressbar-path{
    stroke: #FFC107 !important;
}

.CircularProgressbar-trail{
    stroke: #c0c0ca !important;
}

.dark .CircularProgressbar-trail{
    stroke: #191923 !important;
}

.CircularProgressbar-text{
    fill: #000000 !important;
}

.dark .CircularProgressbar-text{
    fill: #FFFFFF !important;
}