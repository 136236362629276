@font-face {
    font-family: 'Mikhak';
    src: url('files/fonts/Mikhak-Medium.ttf');
}

@font-face {
    font-family: 'Estedad-thin';
    src: url('files/fonts/Estedad-Thin.woff2');
}

@font-face {
    font-family: 'Estedad-light';
    src: url('files/fonts/Estedad-Light.woff2');
}

@font-face {
    font-family: 'Estedad-bold';
    src: url('files/fonts/Estedad-Medium.woff2');
}

.font-mikhak {
    font-family: 'Mikhak';
}

.font-estedad {
    font-family: 'Estedad-thin';
}

.font-estedad-normal {
    font-family: 'Estedad-light';
}

.font-estedad-bold {
    font-family: 'Estedad-bold';
}

.min-w-110px{
    min-width: 110px;
}

.w-30--100{
    width: 30%;
}

.myRotate90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

.myTransitionWidth {
    transition: width ease-out 0.55s;
}

.myTransitionOpacity {
    transition: opacity ease-out 0.55s;
}

.myTransitionAll {
    transition: all ease-out 0.55s;
}

@media only screen and (max-width: 450px) {
    .min-w-75{
        min-width: 75%;
    }
}

.stepperChildBox:after{
    content: '';
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    position: absolute;
    right: -6px;
    top: 8px;
    background-color: #24242F;
}